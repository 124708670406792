<template>
	<div class="col-12 mobileMenu d-lg-none position-sticky w-100" style="max-height: 100vh; overflow-y: scroll;">
		<div class="row">
			<div class="col-12 py-2 mobileMenu position-relative col-12 text-bg-dark">
				<span @click="mobileMenuIsOpen = !mobileMenuIsOpen">
					<svg width="16" height="17" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.33344 13.3957C0.979793 13.3957 0.640626 13.2553 0.390557 13.0052C0.140488 12.7551 0 12.4159 0 12.0623V1.39741C0 1.04376 0.140488 0.704591 0.390557 0.454522C0.640626 0.204453 0.979793 0.0639648 1.33344 0.0639648H12.001C12.3547 0.0639648 12.6938 0.204453 12.9439 0.454522C13.194 0.704591 13.3344 1.04376 13.3344 1.39741V12.0623C13.3344 12.4159 13.194 12.7551 12.9439 13.0052C12.6938 13.2553 12.3547 13.3957 12.001 13.3957H1.33344ZM20.0017 13.3957C19.648 13.3957 19.3088 13.2553 19.0588 13.0052C18.8087 12.7551 18.6682 12.4159 18.6682 12.0623V1.39741C18.6682 1.04376 18.8087 0.704591 19.0588 0.454522C19.3088 0.204453 19.648 0.0639648 20.0017 0.0639648H30.6666C31.0202 0.0639648 31.3594 0.204453 31.6094 0.454522C31.8595 0.704591 32 1.04376 32 1.39741V12.0623C32 12.4159 31.8595 12.7551 31.6094 13.0052C31.3594 13.2553 31.0202 13.3957 30.6666 13.3957H20.0017ZM1.33344 32.064C0.979793 32.064 0.640626 31.9235 0.390557 31.6734C0.140488 31.4233 0 31.0842 0 30.7305V20.063C0 19.7093 0.140488 19.3701 0.390557 19.1201C0.640626 18.87 0.979793 18.7295 1.33344 18.7295H12.001C12.3547 18.7295 12.6938 18.87 12.9439 19.1201C13.194 19.3701 13.3344 19.7093 13.3344 20.063V30.7305C13.3344 31.0842 13.194 31.4233 12.9439 31.6734C12.6938 31.9235 12.3547 32.064 12.001 32.064H1.33344ZM20.0017 32.064C19.648 32.064 19.3088 31.9235 19.0588 31.6734C18.8087 31.4233 18.6682 31.0842 18.6682 30.7305V20.063C18.6682 19.7093 18.8087 19.3701 19.0588 19.1201C19.3088 18.87 19.648 18.7295 20.0017 18.7295H30.6666C31.0202 18.7295 31.3594 18.87 31.6094 19.1201C31.8595 19.3701 32 19.7093 32 20.063V30.7305C32 31.0842 31.8595 31.4233 31.6094 31.6734C31.3594 31.9235 31.0202 32.064 30.6666 32.064H20.0017Z" fill="white"/></svg>
				</span>
			</div>
			<Transition>
				<div class="col-12 py-3 text-bg-dark" v-if="mobileMenuIsOpen" >
					<select class="form-select mb-3" v-model="selectedEnv" @change="onEnvChange">
						<option v-for="(env, index) in envs" :key="'env_'+index" :value="env">{{ env }}</option>
					</select>
					<a href="/home" class="text-white text-decoration-none" @click="mobileMenuIsOpen = false">
						<span class="fs-4">livenetone</span>
					</a>
					<hr>
					<ul class="nav nav-pills flex-column mb-auto">
						<li 
							class="nav-item"
							v-for="(permList, permTitle) in menuByPermissions"
							:key="'perm_'+permTitle"
							@click="mobileMenuIsOpen = false">
							<h5 class="mt-2">{{ permTitle }}</h5>
							<router-link 
								v-for="(perm, permIndex) in permList"
								:key="'perm_'+permTitle+'_'+permIndex"
								:to="'/'+perm.PARENT_SLUG+'/'+perm.SLUG"
								:class="['nav-link text-white']"
								:activeClass="'active'">
								<svg class="bi pe-none me-2" width="16" height="16"><use xlink:href="#speedometer2"></use></svg>
								<span class="position-relative">
									<span v-if="perm.badge && perm.badge.maxComplaints > 0" class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">{{ perm.badge.maxComplaints }}</span>
									{{perm.NAME}}
								</span>
							</router-link>
						</li>
					</ul>
					<hr>
					<LogoutButton :menuItems="settingsMenu"/>
				</div>
			</Transition>
		</div>
	</div>
	<div class="d-lg-flex flex-column flex-shrink-0 p-3 pt-4 text-bg-dark col-2 position-sticky left-0 top-0 d-none" style="height: 100vh; overflow-y: auto;">
		<select class="form-select" v-model="selectedEnv" @change="onEnvChange">
			<option v-for="(env, index) in envs" :key="'env_'+index" :value="env">{{ env }}</option>
		</select>
		<a href="/home" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
			<span class="fs-4">livenetone</span>
		</a>
		<hr>
		<ul class="nav nav-pills flex-column mb-auto">
			<li 
				class="nav-item"
				v-for="(permList, permTitle) in menuByPermissions"
				:key="'perm_'+permTitle">
				<h5 class="mt-2">{{ permTitle }}</h5>
				<router-link 
					v-for="(perm, permIndex) in permList"
					:key="'perm_'+permTitle+'_'+permIndex"
					:to="'/'+perm.PARENT_SLUG+'/'+perm.SLUG"
					:class="['nav-link text-white d-flex align-items-center']"
					:activeClass="'active'">
					<svg class="bi pe-none me-2" width="16" height="16"><use xlink:href="#speedometer2"></use></svg>
					{{perm.NAME}}
					<span v-if="perm.badge && perm.badge.count > 0" class="badge text-bg-danger ms-2" style="font-size: 12px;">{{ perm.badge.count }}</span>
				</router-link>
			</li>
		</ul>
		<hr>
		<LogoutButton :menuItems="settingsMenu"/>
	</div>
	
</template>
<script>
	import { computed, ref } from 'vue';
    import { useStore } from 'vuex';
	import LogoutButton from '@/components/ui/LogoutButton.vue';
	import axios from 'axios'
import { getMaxComplaintsCount } from '@/helpers/api/complaints';
	export default {
		components: {LogoutButton},
		setup() {
			const mobileMenuIsOpen = ref(false)
			const totalComplaints = ref([])
			const envs = ref(['dev', 'prod'])
			const selectedEnv = ref('prod')
            const store = useStore();
			const menuByPermissions = computed(() => {
				return store.state.user.access.filter(el => el.PARENT_SLUG != 'settings' && el.HAS_ACCESS === 1).reduce((accumulator, item) => {
                    const key = item.PARENT_NAME;
						if (!accumulator[key]) {
							accumulator[key] = [];
						}
						item.badge = totalComplaints.value.find(el => el.name == item.PARENT_SLUG+item.SLUG)
						accumulator[key].push(item);
						return accumulator;
                }, {});
			})

			const settingsMenu = computed(() => {
				return store.state.user.access.filter(el => el.PARENT_SLUG == 'settings' && el.HAS_ACCESS === 1)
			})
			const onEnvChange = () => {
				axios.defaults.headers['X-Environment'] = selectedEnv.value

			}
			const requestMaxComplaints = async() => {
				totalComplaints.value = await getMaxComplaintsCount(1)
			}
			requestMaxComplaints()
			return { menuByPermissions, settingsMenu, envs, selectedEnv, mobileMenuIsOpen, onEnvChange }
		},
	}
</script>
<style scoped>
.nav-link.text-white:hover {
    background: var(--bs-nav-link-hover-color);
}
.v-enter-active,
.v-leave-active {
  transition: opacity 250ms ease;
}

.v-enter-from,
.v-leave-to {
	height: 100vh;
}
</style>
